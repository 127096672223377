import React from "react"
import classNames from "classnames"
import { Text } from "../Core"
import { Columns } from "../Containers"

const Heading = ({
  heading,
  blurb,
  boldBlurb = false,
  sideColumnsSize = 2,
  className,
  htmlBefore,
  children
}) => {
  if (!heading) return <></>

  const mainClasses = classNames(className, "page-heading")
  const blurbClasses = classNames({ bold: boldBlurb })

  return (
    <div className={mainClasses}>
      <Columns sideColumnsSize={sideColumnsSize}>
        <div className="column">
          {htmlBefore && htmlBefore}
          <Text as="h1" text={heading} className="px-40--mobile" />
          {blurb && (
            <Text
              className={`${blurbClasses} px-40--mobile`}
              useStringReplace
              text={blurb}
            />
          )}
        </div>
      </Columns>
      {children && children}
    </div>
  )
}

export default Heading
